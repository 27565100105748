.img {
    width: fit-content;
    margin-right: 10px;
}
.login {
    height: 1000px;
    width: 100%;
    background: #fff;
    position: relative;
}
.login_box {
    width: 1050px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 4px 22px -8px #000;
    display: flex;
    overflow: hidden;
}
.login_box .left{
    width: 45%;
    height: 100%;
    padding: 25px 25px;

}
.login_box .right{
    width: 55%;
    height: 100%
}
.left .top_link a {
    color: #452A5A;
    font-weight: 400;
}
.left .top_link{
    height: 20px
}
.left .contact{
    display: flex;
    align-items: center;
    justify-content: center;
    /*align-self: center;*/
    height: 100%;
    width: 73%;
    margin: auto;
    /*background-color: #4f7351;*/
}

.left .contact .login-inputs {
    display: block;
    /*background-color: #23D5AB;*/
    height: 200px;
    width: 100%;
}

.left h3{
    text-align: center;
    margin-bottom: 40px;
}

.left{
    background: linear-gradient(-45deg, #dcd7e0, #fff);
}
.submit {
    border: none;
    padding: 15px 70px;
    border-radius: 8px;
    display: block;
    margin: 120px auto auto;
    background: crimson;
    color: #fff;
    font-weight: bold;
    -webkit-box-shadow: 0px 9px 15px -11px rgba(88,54,114,1);
    -moz-box-shadow: 0px 9px 15px -11px rgba(88,54,114,1);
    box-shadow: 0px 9px 15px -11px rgba(88,54,114,1);
}



.right {
    background-color: white;
    color: #000;
    position: relative;
    backdrop-filter: blur(5px);
}

.right .right-text{
    height: 60%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.right-text h2{
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 25px;
    font-weight: 500;
}

.right-text h5{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 19px;
    font-weight: 400;
}

.right .right-inductor{
    position: absolute;
    width: 70px;
    height: 7px;
    background: #fff0;
    left: 50%;
    bottom: 70px;
    transform: translate(-50%, 0%);
}
.top_link img {
    width: 28px;
    padding-right: 7px;
    margin-top: -3px;
}



