.about-section {
    position: relative;
    width: 100%;
    height: 580px;
    font-family: 'Open Sans', sans-serif;
    /*margin: 40px;*/
    /*background-color: #262626;*/
}

.about-section .section-about-heading {
    position: relative;
    width: 100%;
    color: black;
    text-align: center;
    top: 45px
    /*background-color: #8f10b7;*/
}

.about-section .all-sections {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: 90px;
}
@media (max-width: 1496px) {
    .about-section .all-sections {
        flex-wrap: wrap;
    }
}
.about-section .all-sections .mini-section {
    height: 350px;
    width: 20%;
    border-radius: 20px;
    background-color: white;
    filter: drop-shadow(0 0 0.4rem rgba(0,0,0,0.1));
    transition: ease-in-out box-shadow .5s;
}

.about-section .all-sections .mini-section:hover {
    box-shadow: 0 0 10px rgba(220, 20, 60, 0.6);
    transition: ease-in-out box-shadow .5s;
}
.about-section .all-sections .mini-section .text-mini-section {
    position: relative;
    text-align: center;
    z-index: 1000;
    color: black;
    height: fit-content;
}
.about-section .all-sections .mini-section .text-mini-section h1 {
    font-size: 24px;
    /*background-color: #0077b5;*/
}
.about-section .all-sections .mini-section .text-mini-section p {
    font-size: 16px;
    margin-top: 10px;
    padding: 15px;
}

.about-section .all-sections .mini-section .hexagon {
    position: relative;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
    height: 120px;
    width: 45%;
    /*background-color: #16d7cd;*/
}

.about-section .all-sections .mini-section .hexagon i {
    font-size: 55px;
    color: crimson;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 1496px) {
    .about-section .all-sections .mini-section {
        width: 35%;
        margin-bottom: 50px;
    }
    .about-section .all-sections .mini-section .hexagon {
        width: 32%;
        height: 150px;
    }
}
@media (max-width: 1024px) {
    .about-section .all-sections .mini-section {
        width: 60%;
        margin-bottom: 50px;
    }
    .about-section .all-sections .mini-section .hexagon {
        width: 27%;
        height: 155px;
    }
}
@media (max-width: 920px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 30%;
    }
}
@media (max-width: 800px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 31%;
    }
}
@media (max-width: 730px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 40%;
    }
}
@media (max-width: 635px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 40%;
    }
}
@media (max-width: 550px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 50%;
    }
}
@media (max-width: 400px) {
    .about-section .all-sections .mini-section .hexagon {
        width: 60%;
    }
}