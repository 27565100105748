.about-content-container {
    display: block;
    align-items: center;
    width: 100%;
    height: fit-content;
    color: black;
}

.about-main-content {
    height: 60vh;
    width: 100%;
    background-image: url('../../public/Assets/Images/AboutPage/about-heading.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(30%) blur(8px) saturate(80%);
    margin-bottom: 40px;
}

.about-info {
    color: white;
    display: block;
    position: absolute;
    width: 50%;
    height: fit-content;
    margin-top: -320px;
}

.about-info p {
    font-size: 1.1rem;
    width: 100%;
    vertical-align: center;
    letter-spacing: 2px;
}

.about-buttons {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.about-btn {
    background-color: #dc143c;
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-size: .7em;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1%;
    padding: 15px;
    text-align: center;
    -webkit-text-decoration: #f1f1f1;
    text-decoration: #f1f1f1;
    text-transform: uppercase;
    width: 210px;
    transition: ease-in-out border-radius .3s, ease-in-out border .3s;
}

.about-btn:hover {
    opacity: 1;
    border: 1px solid rgba(220, 20, 60, 1.5);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: ease-in-out border-radius .3s, ease-in-out border .3s;
}

.about-buttons a:hover {
    text-decoration: none;
    color: #f1f1f1;
}

.about-how-we-work {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 10%;
    height: fit-content;
    margin: 0 0 150px 0;
}

.how-we-work-heading {
    width: 100%;
    text-align: center;
}

.how-we-work-section {
    display: flex;
    flex-grow: 1;
    width: 90%;
    height: 400px;
    margin: 60px;
    background-color: #fff;
}

.how-we-work-section-info {
    height: 100%;
    width: 60%;
}

.how-we-work-section-graphic {
    height: 100%;
    width: 40%;
}

.how-we-work-section-graphic .border-box {
    height: 70%;
    width: 50%;
    border: 0.6rem solid;
    border-left: none;
    border-radius: 10px;
}

.how-we-work-section-graphic .border-box img {
    margin-top: -110px;
    width: 140px;
}

.how-we-work-section-graphic .border-box .border-box-inner {
    height: 40%;
    width: 30%;
    border: 0.2rem solid crimson;
    border-right: none;
}

.text-center-about-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
}

.text-center-about-us {
    text-align: center;
    font-size: 5em;
}