
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

.faq-section {
    height: fit-content;
    width: 100%;
}

.faq-heading {
    font-family: Lato;
    font-weight: 400;
    font-size: 19px;
    -webkit-transition: text-indent 0.2s;
    margin-top: 2px;
    text-indent: 20px;
    color: #000;
}

.faq-text {
    display: block;
    height: auto; /* Set height to auto for dynamic resizing */
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #919191;
    margin-bottom: 30px;
}

.faq {
    width: 70%;
    height: fit-content;
    border-radius: 4px;
    position: relative;
}
.faq label {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 56px;
    padding-top:1px;

    background-color: #FAFAFA;
    border-bottom: 1px solid #E1E1E1;
}

.faq input[type="checkbox"] {
    display: none;
}

.faq .faq-arrow {
    width: 8px;
    height: 8px;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-top: 2px solid rgba(0, 0, 0, 0.33);
    border-right: 2px solid rgba(0, 0, 0, 0.33);
    float: right;
    position: relative;
    top: -40px;
    right: 27px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.faq input[type="checkbox"]:checked + label > .faq-arrow {
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.faq input[type="checkbox"]:checked + label {
    display: block;
    background: rgba(255,255,255,255) !important;
    color: #4f7351;
    height: auto;
    transition: height 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.faq input[type='checkbox']:not(:checked) + label {
    display: block;
    transition: height 0.8s;
    height: 60px;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

::-webkit-scrollbar {
    display: none;
}
