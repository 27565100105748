@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.body {
    /*background-color: #007aff;*/
    color: #000;
    height: 100vh;
    width: 100vw;
    position: relative;
    font-size: 100%;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
}

.error-button {
    font-weight: 300;
    color: #000;
    font-size: 1.2em;
    text-decoration: none;
    border: 1px solid crimson;
    padding: .5em;
    border-radius: 3px;
    float: left;
    margin: 6em 0 0 -155px;
    left: 50%;
    position: relative;
    transition: all .3s linear;
}

.error-button:hover {
    background-color: crimson;
    color: #fff;
}

.error-text {
    font-size: 2em;
    text-align: center;
    font-weight: 100;
}

.error-heading {
    text-align: center;
    font-size: 15em;
    font-weight: 100;
    text-shadow: crimson 1px 1px, crimson 2px 2px, crimson 3px 3px, crimson 4px 4px, crimson 5px 5px, crimson 6px 6px, crimson 7px 7px, crimson 8px 8px, crimson 9px 9px, crimson 10px 10px, crimson 11px 11px, crimson 12px 12px, crimson 13px 13px, crimson 14px 14px, crimson 15px 15px;
}