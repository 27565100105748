@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600);
.snip1336 {
    font-family: 'Roboto', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 30px;
    min-width: 290px;
    max-width: 425px;
    border-radius: 10px;
    width: 100%;
    color: #000;
    text-align: left;
    line-height: 1.4em;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(220, 20, 60, 0.2);
    transition: ease-in-out box-shadow .3s;
}

.snip1336:hover {
    box-shadow: 0 0 10px rgba(220, 20, 60, 0.6);
    transition: ease-in-out box-shadow .3s;
}

.snip1336 * {
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.snip1336 img {
    max-width: 100%;
    vertical-align: top;
    opacity: 0.80;
    border-radius: 0 0 20px 20px;
}
.snip1336 figcaption {
    width: 100%;
    /*background-color: #141414;*/
    background-color: rgba(255,255,255,0.4);
    padding: 25px;
    /*border-color: red;*/
    position: relative;
}
.snip1336 figcaption:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 55px 0 0 400px;
    border-color: transparent transparent transparent rgba(255,255,255,0.4);
}
.snip1336 figcaption a {
    border: 1px solid rgba(220, 20, 60, 0.5);
    color: dimgrey;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 10px 0;
    display: inline-block;
    /*opacity: 0.65;*/
    padding: 15px;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
}

.snip1336 figcaption a:hover {
    opacity: 1;
    border: 1px solid rgba(220, 20, 60, 1.5);
    border-radius: 5px;
}

.snip1336 .profile {
    border-radius: 20%;
    position: absolute;
    height: 69px;
    bottom: 100%;
    left: 25px;
    z-index: 1;
    max-width: 90px;
    opacity: 1;
}

.snip1336 .follow {
    margin-right: 4%;
    border-color: #2980b9;
    color: #2980b9;
}

.snip1336 h2 {
    margin: 0 0 15px;
    font-weight: 300;
}

.snip1336 p {
    margin: 0 0 10px;
    font-size: 0.8em;
    letter-spacing: 1px;
    opacity: 0.8;
}
