.contact-container {
    padding-top: 120px;
    margin-bottom: 80px;
}

.contact-container h1 {
    font-family: 'Poppins', sans-serif, 'arial';
    font-weight: 600;
    font-size: 72px;
    color: black;
    text-align: center;
}

.contact-container h4 {
    font-family: 'Roboto', sans-serif, 'arial';
    font-weight: 400;
    font-size: 20px;
    color: #9b9b9b;
    line-height: 1.5;
}

/* ///// inputs /////*/

input[type=text]:focus ~ label, textarea:focus ~ label, input[type=text]:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -12px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .styled-input {
        width:100%;
    }
}

.styled-input label {
    color: #fff;
    padding: 1.3rem 25px 1rem 25px;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide {
    width: 650px;
    max-width: 100%;
}

input,
textarea {
    padding: 24px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: #2d2d2d;
    color: white;
    border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input[type=text]:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto;
}

.submit-btn {
    float: right;
    padding: 10px 35px;
    border-radius: 5px;
    display: inline-block;
    background-color: crimson;
    color: white;
    font-size: 18px;
    cursor: pointer;
    /*box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),*/
    /*0 2px 10px 0 rgba(0,0,0,0.07);*/
    /*-webkit-transition: all 300ms ease;*/
    transition: all 300ms ease;
}

.submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
    0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
    .submit-btn {
        width:100%;
        float: none;
        text-align:center;
    }
}

input[type=checkbox] {
    /*background-color: #E4405F;*/
    width: 30px;
    /*height: 5px;*/
    /*margin-top: -1000px;*/
    /*padding-top: -1000px;*/
    /*top: 0;*/
}

input[type=checkbox] + label {
    color: #000000;
    font-style: italic;
    font-size: 14px;
}

input[type=checkbox]:checked + label {
    color: #f00;
    font-style: normal;
}

/* Candidate Registration Form */

.candidate-section {
    width: 100%;
    height: 165vh;
    margin-top: 60px;
    /*background-color: #fff;*/
    padding: 0 100px;
}

.candidate-container {
    width: fit-content;
    height: fit-content;
    margin-top: 50px;
    background-color: white;
    /*padding: 0 100px;*/
}

:root {
    --arrow-bg: rgba(255, 255, 255, 0.3);
    --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/b/ba/Ic_keyboard_arrow_down_48px.svg);
    --option-bg: #2d2d2d;
    --select-bg: #2d2d2d;
}

.select-field {
    /* Reset */
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 18.5rem;
    padding: 1rem 4rem 1rem 1rem;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
    color: white;
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* Remove IE arrow */
    &::-ms-expand {
        display: none;
    }
    /* Remove focus outline */
    &:focus {
        outline: none;
    }
    /* <option> colors */
}

select option {
    color: white;
    background-color: #2d2d2d;
}

/* Checkboxes */
/*input[type=checkbox] + label {*/
/*    display: block;*/
/*    margin: 0.2em;*/
/*    cursor: pointer;*/
/*    padding: 0.2em;*/
/*}*/

/*input[type=checkbox] {*/
/*    display: none;*/
/*}*/

/*input[type=checkbox] + label:before {*/
/*    content: "\2714";*/
/*    border: 0.1em solid #000;*/
/*    border-radius: 0.2em;*/
/*    display: inline-block;*/
/*    width: 1em;*/
/*    height: 1em;*/
/*    padding-left: 0.2em;*/
/*    padding-bottom: 0.3em;*/
/*    margin-right: 0.2em;*/
/*    vertical-align: bottom;*/
/*    color: transparent;*/
/*    transition: .2s;*/
/*}*/

/*input[type=checkbox] + label:active:before {*/
/*    transform: scale(0);*/
/*}*/

/*input[type=checkbox]:checked + label:before {*/
/*    background-color: MediumSeaGreen;*/
/*    border-color: MediumSeaGreen;*/
/*    color: #fff;*/
/*}*/

/*input[type=checkbox]:disabled + label:before {*/
/*    transform: scale(1);*/
/*    border-color: #aaa;*/
/*}*/

/*input[type=checkbox]:checked:disabled + label:before {*/
/*    transform: scale(1);*/
/*    background-color: #bfb;*/
/*    border-color: #bfb;*/
/*}*/