.recruit-content-container {
    display: block;
    /*flex-direction: row;*/
    align-items: center;
    width: 100%;
    height: fit-content;
    /*padding: 50px;*/
    /*background-color: #2980b9;*/
    color: black;
}

.recruit-main-content {
    height: 60vh;
    width: 100%;
    background-image: url('../../public/Assets/Images/AboutPage/about_heading_img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(30%) blur(8px) saturate(80%);
    margin-bottom: 40px;
}

.recruit-info {
    color: white;
    padding-top: 170px;
    filter: drop-shadow(0 0 0.23em black);
}

.recruit-info p {
    font-size: 1.1rem;
    width: 100%;
    vertical-align: center;
    letter-spacing: 2px;
}

.recruit {
    height: 80vh;
}

.recruit-heading {
    width: 100%;
    height: 30px;
    text-align: center;
}

.recruitment-section {
    width: 100%;
    height: 70vh;
    margin-top: 50px;
    padding: 0 100px;
}