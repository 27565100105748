.content {
    display: flex;
    flex-direction: column;
    /*position: absolute;*/
    height: 100%;
    width: 100%;
    max-width: 1920px;
    background-color: rgba(255, 255, 255, 0);
}

.section1, .section2, .section3, .section4, .section5 {
    display: block;
    position: relative;
    height: fit-content;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);
}
/*.section1 {*/
/*    !*background-color: crimson;*!*/
/*}*/
/*.section2 {*/
/*    !*background-color: aqua;*!*/
/*}*/
/*.section3 {*/
/*    !*background-color: blue;*!*/
/*}*/

.section5 {
    height: 100vh;
    background-color: blue;
}