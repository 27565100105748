.insights-info h1, .insights-info p {
    filter: drop-shadow(0 0 0.13rem black);
}

.main {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    height: fit-content;
}

.main ul {
    font-size: 1.2em;
}

.dynamic {
    flex: 1;
    width: calc(50% - 5px); /* Adjust the width to fit two divs with a small gap */
    height: 320px;
    margin-right: 10px; /* Add a small gap */
    margin-bottom: 10px; /* Add a gap between rows */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: flex ease-in-out 1s;
}

.dynamic:nth-child(even) {
    margin-right: 0; /* Remove the margin from even-indexed dynamic divs to fit two divs in each row */
}

.dynamic:hover {
    flex: 1.3; /* 65% width */
    transition: flex ease-in-out 1s;
}

.dynamic:not(:hover) {
    flex: 0.7; /* 35% width */
    transition: flex ease-in-out 1s;
}

.dynamic .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: linear-gradient(360deg, #000, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0));
    color: black;
    box-sizing: border-box;
    transition: background 0.3s ease;
}

.dynamic:hover .overlay {
    background: linear-gradient(360deg, #000, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0));
}

.dynamic .overlay h6 {
    margin: 0px;
    font-size: 1.2rem;
    color: white;
}

.dynamic .overlay p {
    font-size: 15px;
    color: white;
}

.insights-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(3px) grayscale(30%);
}

.image1 {
    background-image: url("/public/Assets/Images/Insights/communication.jpg");
}

.image2 {
    background-image: url("/public/Assets/Images/Insights/childcare.jpg");
}

.image3 {
    background-image: url("/public/Assets/Images/Insights/oppurtunities.jpg");
}

.image4 {
    background-image: url("/public/Assets/Images/Insights/trends.jpg");
}

.insights-btn {
    display: inline-block;
    height: fit-content;
    width: fit-content;
    border: 1px solid crimson;
    color: #fff;
    background-color: #000;
    font-size: 0.7em;
    text-transform: uppercase;
    padding: 12px;
    border-radius: 5px;
    text-align: center;
    text-decoration: #f1f1f1;
    font-weight: 600;
    letter-spacing: 1px;
}