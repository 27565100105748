.how-we-work-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 560px;
    color: #fff;
}

.how-we-work-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-image: url('../../../public/Assets/Images/pexels-tbee-82256.jpg');
    background-size: auto;
    background-repeat: no-repeat;
    filter: grayscale(20%) blur(5px);
}

.how-we-work-text {
    display: block;
    position: absolute;
    height: 100%;
    width: 50%;
    padding: 0 8%;
    margin-left: 5%;
}


.how-we-work-text h1 {
    width: 100%;
    vertical-align: center;
    font-size: 3rem;
    margin-top: 0;
    padding-top: 0;
}

.how-we-work-text p {
    font-size: 1rem;
    width: 100%;
    margin: 0 0 10px;
    letter-spacing: 2px;
}

.how-we-work-text a {
    border: 1px solid rgba(220, 20, 60, 0.5);
    color: dimgrey;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 10px 0;
    display: inline-block;
    /*opacity: 0.65;*/
    padding: 15px;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #f1f1f1;
}

.how-we-work-text a:hover {
    opacity: 1;
    border: 1px solid rgba(220, 20, 60, 1.5);
    border-radius: 5px;
}

.how-we-work-categories {
    display: block;
    position: absolute;
    height: 100%;
    margin-left: 45%;
    width: 50%;
    margin-right: 50%;
}

.how-we-work-categories-sections {
    height: 80%;
    width: 80%;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
}

.grid-item {
    height: 90%;
    width: 90%;
    margin: 15px;
    background-color: white;
    border-radius: 10px;
    color: black;
    transition: border-radius 0.6s ease-in, background-color 0.6s ease-in;
}

.grid-item:hover {
    border-radius: 50px 0 50px 10px;
    transition: border-radius 0.6s ease-out, background-color 0.6s ease-out;
    background: linear-gradient(70deg, azure, #fff, rgba(220, 20, 6, .3));
    color: black;
}

.grid:nth-child(2) .grid-item:nth-child(2) {
    background-color: rgba(0,0,0,0);
    color: rgba(0,0,0,0);
}

.grid:nth-child(2) .grid-item:nth-child(2):hover {
    background: rgba(0,0,0,0);
}

