.services-content-container {
    display: block;
    align-items: center;
    width: 100%;
    height: fit-content;
    color: black;
}

.services-main-content {
    height: 60vh;
    width: 100%;
    background-image: url('../../public/Assets/Images/AboutPage/about-heading.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(30%) blur(8px) saturate(80%);
    margin-bottom: 40px;
}

.video {
    filter: grayscale(40%) blur(6px) saturate(80%);
}

.services-info {
    color: white;
    display: block;
    position: absolute;
    width: 50%;
    height: fit-content;
    margin: -400px 25% 0;
}

.services-info::before {
    filter: grayscale(40%) blur(8px) saturate(80%);
}

.services-info p {
    font-size: 1.1rem;
    width: 100%;
    vertical-align: center;
    letter-spacing: 2px;
}

.services-buttons {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.services-btn {
    border: 1px solid white;
    display: inline-block;
    color: #fff;
    background-color: rgb(220, 20, 60);
    font-size: 0.7em;
    text-transform: uppercase;
    padding: 15px;
    width: 210px;
    text-align: center;
    text-decoration: #f1f1f1;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1%;
}

.about-btn:hover {
    border-radius: 5px;
}

.about-buttons a:hover {
    text-decoration: none;
    color: #f1f1f1;
}

.services-section {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 80%;
    /*padding: 0 10%;*/
    border-radius: 15px;
    height: fit-content;
    margin: 60px 0;
    background-color: rbga(255, 255, 255, 0.3);
    backdrop-filter: blur(3px);
    margin: auto;
}

.services-content-section {
    display: inline-block;
    box-sizing: border-box;
    width: fit-content;
}

.services-content-section p {
    padding: 10px 0;
    color: dimgrey;
    font-size: 20px;
}

.people-card-deck {
    width: 80%;
    margin-bottom: 2%;
}

.card {
    background: white;
    height: 260px;
    aspect-ratio: 9/5;
    border-radius: 1rem;
    border-color: crimson;
    color: black;
    transition: border-radius 0.6s ease-in, background-color 0.6s ease-in;
}

.card:hover {
    border-radius: 50px 0 50px 10px;
    transition: border-radius 0.6s ease-out, background-color 0.6s ease-out;
    background: linear-gradient(70deg, azure, #fff, rgba(220, 20, 6, .3));
    color: black;
}

.card::after {
    transition: border-radius .6s ease-out, background .6s ease-out, border .6s ease-in-out;
}

.people-cards {
    height: 180px;
    aspect-ratio: 8/4;
    text-align: center;
}


.card-deck {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat( 3, 1fr);
    padding: 1rem;
    z-index: 10000000;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
}

.card-content p {
    height: 50px;
    padding: 10px 0;
    color: dimgrey;
}

.card-content a {
    border: 1px solid rgba(220, 20, 60, 0.5);
    color: dimgrey;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 10px 0;
    display: inline-block;
    padding: 15px;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: white;
}

.card-content a:hover {
    opacity: 1;
    border: 1px solid rgba(220, 20, 60, 1.5);
    border-radius: 5px;
}

/*.card + .card-content .countries  {*/
/*    border: none;*/
/*}*/

/* Video Elements */
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    filter: grayscale(10%) blur(8px) saturate(80%);
}


.countries {
    /*background-color: rgba(0, 0, 0, 0.2);*/
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    opacity: 0;
    border: none;
    transition: border-radius .6s ease-out, background .6s ease-out, border .6s ease-in-out;
    color: white;
}

.countries p {
    color: black;
    width: 100%;
    height: 60%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.countries p {
    color: white;
    filter: drop-shadow(0 0 0.75rem black);
}

.countries:hover {
    /*background: linear-gradient(70deg, white, ghostwhite, rgba(220, 20, 6), #dc143c);*/
    background: rgba(126, 126, 126, 0.7);
    /*background: radial-gradient(#2d2d2d, #7e7e7e, #2d2d2d);*/
    width: 100%;
    height: 100%;
    border-radius: 50px 0 50px 10px;
    opacity: 1;
    transition: border-radius .6s ease-out, background .6s ease-out, border .6s ease-in-out;
}

.countries-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(3px) grayscale(30%);
}

.dynamic .countries-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: linear-gradient(360deg, #000, rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
    color: black;
    box-sizing: border-box;
    transition: background 0.3s ease;
}

.dynamic:hover .countries-overlay {
    background: linear-gradient(360deg, #000, rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
}

.dynamic .countries-overlay h6 {
    margin: 0px;
    font-size: 1.5rem;
    color: white;
}

.dynamic .countries-overlay p {
    font-size: 20px;
    color: white;
}