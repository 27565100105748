body {
    width: 100%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0);
}

.main-content {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#particles-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*overflow-y: auto;*/
    overflow: hidden;
    background-color: transparent;
    z-index: -1000000000000000;
}