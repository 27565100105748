.headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 220px;
    margin: 0;
    /*background-color: #117964;*/
    color: #000;
}

.headline h1 {
    width: 100%;
    vertical-align: center;
    text-align: center;
    height: 100%;
    font-size: 2.4rem;
    margin: 0;
    padding-top: 25px;
    /*background-color: purple;*/
}

.headline p {
    font-size: 1.1rem;
    width: 60%;
    /*height: fit-content;*/
    height: 120px;
    text-align: center;
    letter-spacing: 2px;
    /*background-color: #2980b9;*/
}

.profiles {
    display: flex;
    width: 100%;
    height: fit-content;
    /*padding: 30px;*/
    align-items: center;
    margin-bottom: 40px;
    /*background-color: red;*/
}

.profiles-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: auto;
    padding-bottom: 20px;
    /*background-color: #117964;*/
}