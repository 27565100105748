.carousel-img-container {
    position: relative;
    width: 100%;
    height: 100%; /* Set the desired height for your div */
    overflow: hidden;
}

.carousel-img {
    width: 50%;
    /*height: 20%;*/
    display: block;
    /*background-color: #3B5998;*/
}